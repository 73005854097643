<template>
  <div>
    <vx-card title="Politiques juridiques, confidentialité et de sécurité" class="mb-4">
      <div slot="no-body">
        <div class="p-8">
          <div>
            <div>
              <label class="vs-input--label mb-4">Contenu</label>
              <quill-editor v-model="content"></quill-editor>
            </div>
            <div class="mt-4">
              <div class="inline-flex">
                <div>
                  <vs-button
                    class="w-full sm:w-auto"
                    :disabled="!validateForm || loadingUpdatePolicy"
                    @click="fetchApplyUpdatePolicy"
                  >Valider</vs-button>
                </div>
                <div class="ml-2">
                  <clip-loader :loading="loadingUpdatePolicy" :color="themeColors.primary"></clip-loader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import vSelect from "vue-select";
import { mixin } from "@/mixins/mixin";

export default {
  components: {
    vSelect,
    ClipLoader,
    quillEditor
  },
  data() {
    return {
      role: {},
	  loading: false,
	  loadingUpdatePolicy: false,
      content: "",
      error: false,
      themeColors: {},
      errorMsg: ""
    };
  },
  created() {
    Validator.localize("fr", VeeValidateFR);
    this.themeColors = themeColors;
  },
  mounted() {
    this.fetchApplyGetPolicy();
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    validateForm() {
      return !this.errors.any() && this.content != "";
    }
  },
  methods: {
	  ...mapActions("general", [
      "applyGetSetting",
      "applyAddSetting",
    ]),
    acceptAlert(color, title, text) {
      this.$vs.notify({
        color,
        title,
        text
      });
    },
    async fetchApplyGetPolicy() {
      this.loading = true;
      let data = {
        name: "content_policy"
      };
      try {
        let res = await this.applyGetSetting(data);
        if (res.data.value) this.content = res.data.value;

        this.loading = false;
      } catch (err) {
        this.loading = false;
        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({
              name: "Login"
            });
          } else {
            this.acceptAlert(
              "danger",
              "Politiques juridiques",
              "Une erreur s'est produite"
            );
          }
        }
        this.error = true;
      }
    },
    async fetchApplyUpdatePolicy() {
      if (!this.validateForm) return false;

      let data = {
        name: "content_policy",
        value: this.content
      };
      this.loadingUpdatePolicy = true;

      try {
        await this.applyAddSetting(data);
        this.loadingUpdatePolicy = false;
        this.acceptAlert(
          "success",
          "Politiques juridiques",
          "Ajouté avec succès"
        );
      } catch (err) {
        this.loadingUpdatePolicy = false;
        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({
              name: "Login"
            });
          } else {
            this.acceptAlert(
              "danger",
              "Politiques juridiques",
              "Une erreur s'est produite"
            );
          }
        }
        this.error = true;
      }
    }
  }
};
</script>

<style >
</style>
